import styled from 'styled-components';

const ExploreTheFullSectionStyle = styled.section`
	--distanceDescriptionMargin: var(--sp8x);

	--staticSectionPadT: var(--sp16x);
	--borderRadiusStaticSection: var(--sp8x);
	--distanceTitleMargin: var(--sp4x);

	--staticSectionPadLR: var(--sp24x);

	overflow: hidden;

	.wrapper-explore-section {
		position: relative;
		width: 100%;
		padding: var(--staticSectionPadT) 0;
	}

	.anim-background {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background-color: var(--blue1000Color);
		border-radius: var(--borderRadiusStaticSection);
		transform: scaleX(1.3);
		transition: transform 0.5s ease-out;
		z-index: -1;
	}

	.row {
		margin: 0 auto;
	}

	.section-distance {
		margin-bottom: var(--sectionDistance);
	}

	.left-section {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.title-explore-the-full {
		margin-bottom: var(--distanceTitleMargin);
	}

	.description-explore-the-full {
		margin-bottom: var(--distanceDescriptionMargin);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--distanceDescriptionMargin: var(--sp6x);

		--staticSectionPadT: var(--sp13x);
		--staticSectionPadLR: var(--sp18x);
		--borderRadiusStaticSection: var(--sp6x);
		--distanceTitleMargin: var(--sp3x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--distanceDescriptionMargin: var(--sp5x);

		--staticSectionPadT: var(--sp10x);
		--staticSectionPadLR: var(--sp15x);
		--borderRadiusStaticSection: var(--sp5x);
		--distanceTitleMargin: var(--sp2x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--distanceDescriptionMargin: var(--sp4x);

		--staticSectionPadT: var(--sp8x);
		--staticSectionPadLR: var(--sp13x);
		--borderRadiusStaticSection: var(--sp5x);
		--distanceTitleMargin: var(--sp2x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--distanceDescriptionMargin: var(--sp4x);

		--staticSectionPadT: var(--sp8x);
		--staticSectionPadLR: var(--sp13x);
		--borderRadiusStaticSection: var(--sp5x);
		--distanceTitleMargin: var(--sp2x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--distanceDescriptionMargin: var(--sp3x);

		--staticSectionPadT: var(--sp8x);
		--staticSectionPadLR: var(--sp8x);
		--borderRadiusStaticSection: var(--sp5x);
		--distanceTitleMargin: var(--sp2x);

		.wrapper-explore-section {
			padding: var(--staticSectionPadT) var(--staticSectionPadLR);
		}

		.left-section {
			margin-bottom: var(--sp5x);
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--distanceDescriptionMargin: var(--sp3x);

		--staticSectionPadT: var(--sp6x);
		--staticSectionPadLR: var(--sp3x);
		--borderRadiusStaticSection: var(--sp3x);
		--distanceTitleMargin: var(--sp2x);

		.wrapper-explore-section {
			padding: var(--staticSectionPadT) var(--staticSectionPadLR);
		}

		.left-section {
			margin-bottom: var(--sp4x);
		}
	}
`;

export default ExploreTheFullSectionStyle;
