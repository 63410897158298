import gsap from 'gsap';
import { useEffect, useRef } from 'react';
import { useParams, usePathname } from 'next/navigation';

//* HOC'S
import { withDataContext } from '@/context';

//* Style
import ExploreTheFullSectionStyle from './style';

//* Helpers
import { config, useActiveSection } from '@/helpers';

//* Lottie
import explore from '@/lottieFiles/explore-illustration.json';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';
import LottieAnimation from '@/components/global/LottieAnimation';

const ExploreTheFullSection = ({ className, globalData }) => {
	//! Data
	const { call_to_action_title, call_to_action_description } = { ...globalData.map };

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Refs
	const titleRef = useRef();
	const descriptionRef = useRef();
	const containerRef = useRef();
	const oneTime = useRef(false);

	//! Active Section
	const activeSection = useActiveSection(containerRef, 95);

	useEffect(() => {
		gsap.set('.anim-background', {
			scaleX: 1.3,
		});
	}, [pathname, params.lang]);

	useEffect(() => {
		if (activeSection && !oneTime.current) {
			gsap.to('.anim-background', {
				scaleX: 1,
			});

			oneTime.current = true;
		}
	}, [activeSection]);

	return (
		<ExploreTheFullSectionStyle
			ref={containerRef}
			className={className}>
			<Container isSection>
				<div className='row wrapper-explore-section'>
					<div className='anim-background' />

					<div className='col-1' />

					<div className='left-section col-5 col-t-12'>
						<ScrollActive
							animTitle
							allRefs={titleRef}>
							<Text
								ref={titleRef}
								tag={'h2'}
								className={'uppercase h2 white-color font-montserrat-medium title-explore-the-full opacity-0'}>
								{call_to_action_title}
							</Text>
						</ScrollActive>

						<ScrollActive
							animOpacity
							allRefs={descriptionRef}
							duration={2}
							// delay={0.3}
						>
							<Text
								ref={descriptionRef}
								className={'p2 white-color font-active-grotesk-normal description-explore-the-full opacity-0'}>
								{call_to_action_description}
							</Text>
						</ScrollActive>

						<Button
							btnType={'oval'}
							text={'interactiveMap'}
							className={'button-dowland reverse'}
							url={config.routes.interactiveMap.path}
						/>
					</div>

					<div className='col-1' />

					<div className='right-section col-4 col-t-12'>
						<LottieAnimation
							loop
							autoplay
							animData={explore}
						/>
					</div>

					<div className='col-1' />
				</div>
			</Container>
		</ExploreTheFullSectionStyle>
	);
};

export default withDataContext(ExploreTheFullSection, ['globalData']);
